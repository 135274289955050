export default {
	pink: '#ff5ee6',
	lightpink: '#ff8aed',
	yellow: '#fff952',
	lightblue: '#4fffff',
	green: '#52ff6e',
	peach: '#ff6e66',
	black: '#000008',
	grey: '#34343c',
}
